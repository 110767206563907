const CLEANEXPRESSIONID = "8b577e6f-65e8-4cd3-8f9c-647340abf007"
const OVALOOPTESTIDS = ["4e253e0a-529a-4281-9958-0b6b6ec8ae30", "53e46c41-4cf1-468b-8abf-b6c6f2d1b5cc", "3ecc44aa-cd76-4bd4-93a8-66800d2b222e"]
const premiumAndStarterName = ["premium", "starter", "Premium", "Starter"]

export const ovaloopPayAccountIds = [
	"15348232-2714-4bda-8e1d-498d7b06bc82", // Royal Hair
	"ec339135-6de6-45f1-8bc2-93f81a5be066", // Baff-up
	"8b577e6f-65e8-4cd3-8f9c-647340abf007", // clean Ex
	"4092c7ce-e5dc-439a-9498-1593085bcc5d", // switch cosmetic
	"f2c0e771-25c2-46a0-ab6f-a07c9e02257d", // superior mobile
	"edd25ebc-1664-4105-9a6c-3ba97ae94252", // Personal account
	...OVALOOPTESTIDS
]


export const PRODUCTION_MODULE_ACCOUNTS = [
	"f234938f-9ade-4ce3-af62-9b48ebcf1026", // nuts about cake
	"1ffa1601-6f96-4336-b708-475def927014", // oloja bakery,
	"59bcdec7-9187-44b0-843c-104335a3ea5f", // Salivate Restaurant
	"7d726549-d5a1-42cf-9f4a-2ebcd35e0729", // opik gold resources
	...OVALOOPTESTIDS, // ovaloop test account
]

export const CUSTOMER_SUBSCRIPTION_MODULE_ACCOUNTS = [
	CLEANEXPRESSIONID,
	...OVALOOPTESTIDS,
	"7c6fc093-98a0-4343-a12a-b34f703ebf7c" // Sparkuls Ventures

]

export const PRICE_CHECKER_MODULE_ACCOUNTS = [
	...OVALOOPTESTIDS,
	"3dd2ccae-4ccd-4203-b917-d889948f088c", // M-mart
	"9b4c04a1-9a62-47de-8380-104293fecf1b", // Canis mart
	"cb3589fc-ecf9-4f5c-bc0c-966a6be2b7a4" // SnS supermarkets
]

export const onlyShowModulesBasedOnAccountsId = (userProfile, accountIds = [], businessAccountId) => {
	if (!!businessAccountId) {
		return accountIds.includes(businessAccountId)
	}
	
	if (accountIds.includes(userProfile?.[0]?.account)) {
		return true
	}
}


export const isPremiumOrStarterPackage = (userProfile) => {
	return premiumAndStarterName.includes(userProfile?.[0]?.package_details?.package_name)
}


const disableSellBelowStockAccountIds = ["53e46c41-4cf1-468b-8abf-b6c6f2d1b5cc", "54d4eb6e-3fee-426f-8999-cfeb2adad370"]

export const disableSellBelowStockWhenOfflineForAccount = (accountId) => disableSellBelowStockAccountIds.includes(accountId)