import {
  CreateCategoryModalStyled,
  ModalLayoutStyled,
  ReceiptModalStyled,
  ReceiptOrderTableStyled
} from "../../../styles/modalStyles";
import {
  AllCenterStyled,
  FlexColumnStyled,
  FlexStyled,
  SectionStyled,
  SpaceBetweenStyled
} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, Line} from "../../index";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useBankQuery} from "../../../hooks/useServices/useBankServices";
import {Fragment, useEffect, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useCustomerByIdQuery} from "../../../hooks/useServices/useCustomerServices";
import Barcode from "react-barcode";
import {currencyFormatterFn} from "../../../utils/dataTypeFormatter";
import moment from "moment"
import lodash from "lodash"
import {formatPhoneNumber} from "../../../utils/textTransformer";
import {useModal} from "../../../hooks";
import {handleMainAddress} from "../../../services/customerServices/customerServiceStructure";

const ReceiptModal = ({onClose, data: _data, isFirstPrint}) => {
  // remove returned orders from receipt
  const data = _data.filter(product => !(product?.returned === true && product?.return_type === "order"))
  const [isWayBillReceipt, setWayBillReceipt] = useModal()
  const [isInvoiceReceipt] = useModal(!!localStorage.getItem("invoice_receipt"))
  
  const receiptRef = useRef(null)
  const {data: allBanks} = useBankQuery()
  const {isMobile} = useGlobalContext()
  const {data: customerData} = useCustomerByIdQuery(data?.[0]?.customer?.id)
  const {currencyFormatter} = useDataTypeFormatter()
  
  const bank = allBanks?.find(bank => bank?.id === data[0].banks?.id)
  
  const availableOrders = data.filter(order => !(order.returned === true && order.return_type === "order"))
  const orderSummary = availableOrders.reduce((prev, curr) => {
    const values = {...prev}
    values.totalTax = values.totalTax + Math.abs(Number(curr.tax_amount))
    values.totalCost = values.totalCost + (Number(curr?.product_cost_before_tax) || Number(curr?.product_cost) || 0)
    values.totalService = values.totalService + Number(curr.service_cost)
    values.totalDiscount = values.totalDiscount + Number(curr.discount_amount)
    values.subTotal = values.subTotal + (Number(curr?.product_cost_before_tax) || Number(curr?.product_cost) || 0)
    values.totalExchangeAmountValue = values.totalExchangeAmountValue + Number(curr?.exchange_value || 0)
  
    return values
  }, {subTotal: 0, totalTax: 0, totalService: 0, totalDiscount: 0, totalCost: 0, totalExchangeAmountValue: 0})
  
  const handlePrintReceipt = useReactToPrint({
    content: () => {
      const newReceiptNode = receiptRef.current.cloneNode(true)
      const actionEl = newReceiptNode.querySelector(".receipt_action")
      newReceiptNode.firstElementChild.lastElementChild.removeChild(actionEl)
  
      if (isMobile) {
        const closeActionEl = newReceiptNode.querySelector(".close_action")
        newReceiptNode.firstElementChild.lastElementChild.removeChild(closeActionEl)
      }
  
      return newReceiptNode
    },
    ...(isFirstPrint && {
      onAfterPrint: () => onClose(),
      onPrintError: () => onClose()
    })
  })
  
  const isOrderStatusPending = () => {
    return data?.[0]?.group_order?.status === "pending" || data?.[0]?.bulk_details.status === "pending"
  }
  
  const getTotalAmountPaid = () => {
    return data?.[0]?.bulk_details?.split?.reduce((prev, curr) => {
      return prev + Number(curr.amount)
    }, 0)
  }
  
  const customer = data?.[0]?.selected_customer?.first_name ? data?.[0]?.selected_customer : customerData

  const business_details = typeof data?.[0]?.business === "string" ? (data?.[0]?.business_details || {}) : (data?.[0]?.business || {})
  
  const getPaymentLogs = () => {
    const transactions = data?.[0]?.bulk_details?.pay_log || data?.[0]?.bulk_details?.split || []
    const payment_mode = {cash: 0, transfer: 0, pos: 0}
    
    transactions.forEach(transaction => {
      const method = transaction.method.toLowerCase()
      payment_mode[method] = payment_mode[method] + Number(transaction.amount)
    })
  
    return [
      ...(!!payment_mode.pos ? [{method: "Pos", amount: payment_mode.pos}] : []),
      ...(!!payment_mode.cash ? [{method: "Cash", amount: payment_mode.cash}] : []),
      ...(!!payment_mode.transfer ? [{method: "Transfer", amount: payment_mode.transfer}] : []),
    ]
  }
  
  useEffect(() => {
    if (isFirstPrint) handlePrintReceipt()
  }, [isFirstPrint])
  
  return (
    <ModalLayoutStyled onClick={onClose} $noScroll>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()} ref={receiptRef} className="print_receipt">
        {isMobile && <><br/><br/><br/></>}
        
        <ReceiptModalStyled $isWayBill={localStorage.getItem("isWayBill") === "true"}>
          <FlexColumnStyled className="header">
            <Heading color="black" bold>{business_details?.store_name}</Heading>
            {business_details?.tagline &&
              <Heading color="black" className="phone"><i>{business_details?.tagline}</i></Heading>}
            {business_details?.address &&
              <Heading color="black" className="phone">{business_details?.address}</Heading>}
            {business_details?.phone &&
              <Heading color="black" className="phone">
                TEL:
                {` ${formatPhoneNumber(business_details?.phone)}${!!formatPhoneNumber(business_details?.phone2) ? `, ${formatPhoneNumber(business_details?.phone2)}` : ''}`}
              </Heading>
            }
          </FlexColumnStyled>
    
          <SectionStyled $mb={0}>
  
            <FlexColumnStyled $gap={.1}>
              {isOrderStatusPending() && (
                <SpaceBetweenStyled>
                  <Paragraph color="black" bold className="section_title">Payment Status:</Paragraph>
                  <Paragraph color="black" bold>Pending</Paragraph>
                </SpaceBetweenStyled>
              )}
    
              <SpaceBetweenStyled>
                <Paragraph bold color="black" className="section_title">Rep:</Paragraph>
                <Paragraph bold color="black">{data?.[0]?.staff?.name || data?.[0]?.staff_name}</Paragraph>
              </SpaceBetweenStyled>
    
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Date Created:</Paragraph>
                <Paragraph color="black"
                           bold>{moment(data[0]?.date_created).format('D MMM YYYY [at] h:mm A')}</Paragraph>
              </SpaceBetweenStyled>
            </FlexColumnStyled>

            <FlexColumnStyled $gap={.2} className="order_details">
              {!!customer?.first_name && (
                <Fragment>
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Customer Name:</Paragraph>
                    <Paragraph color="black" bold>{customer?.first_name} {customer?.last_name}</Paragraph>
                  </SpaceBetweenStyled>
    
                  <Fragment>
                    <SpaceBetweenStyled>
                      <Paragraph color="black" bold className="section_title">Customer Phone:</Paragraph>
                      <Paragraph color="black" bold>{customer?.phone}</Paragraph>
                    </SpaceBetweenStyled>
      
                    <SpaceBetweenStyled id="address_div">
                      <Paragraph color="black" bold className="section_title">Customer Address:</Paragraph>
                      <Paragraph color="black" bold>{handleMainAddress(customer?.address) || "No address"}</Paragraph>
                    </SpaceBetweenStyled>
      
                    {handleMainAddress(customer?.address) && <Fragment><br/> <br/> <br/></Fragment>}
                  </Fragment>
                </Fragment>
              )}
  
              {/*<SpaceBetweenStyled>*/}
              {/*  <Paragraph color="black" bold className="section_title">Payment Method:</Paragraph>*/}
              {/*  <Paragraph color="black" bold>*/}
              {/*    {(data[0]?.pay_mode).toUpperCase()}*/}
              {/*  </Paragraph>*/}
              {/*</SpaceBetweenStyled>*/}
  
              {!!data?.[0]?.subscription?.id && (
                <SpaceBetweenStyled>
                  <Paragraph color="black" bold className="section_title">Subscription Type:</Paragraph>
                  <Paragraph color="black" noTop>
                    {data[0].subscription.name}
                  </Paragraph>
                </SpaceBetweenStyled>
              )}
  
              {!isFirstPrint && (
                <SpaceBetweenStyled>
                  <Paragraph bold color="gray" className="section_title">
                    {isInvoiceReceipt ? "Invoice Re-printed" : "Receipt Re-printed"}
                  </Paragraph>
                </SpaceBetweenStyled>
              )}

            </FlexColumnStyled>
  
            <ReceiptOrderTableStyled $headingCount={3}>
              <tr className="table_first_row">
                <th><Paragraph color="black" bold>Product Name</Paragraph></th>
                <th className="table_order_qty"><Paragraph color="black" bold>Qty</Paragraph></th>
                {!isWayBillReceipt && (
                  <th><Paragraph color="black" bold>Amount</Paragraph></th>
                )}
              </tr>
    
              {data.map(product => (
                <tr>
                  <td>
                    <Paragraph
                      color="black" bold>{product?.product?.name || product?.product_name || product?.name}</Paragraph>
                  </td>
                  <td className="table_order_qty">
                    <Paragraph color="black" bold>
                      {!!product?.meta_measurement?.name ?
                        `${product?.meta_measurement?.quantity} ${product?.meta_measurement?.name}`
                        : `${product?.qty} ${product?.product?.unit_measurement || ""}`}
                      <br/>
                      {!!isInvoiceReceipt && `x${currencyFormatter(product.unit_cost)}`}
                    </Paragraph>
                  </td>
                  {!isWayBillReceipt && (
                    <td>
                      <Paragraph color="black" bold>
                        {!!product?.complimentary && ('Complimentary')}
                        {!product?.complimentary && (
                          currencyFormatter((Number(product?.product_cost_before_tax) || Number(product?.product_cost) || 0))
                        )}
                      </Paragraph>
                    </td>
                  )}
                </tr>
              ))}
            </ReceiptOrderTableStyled>
  
            {!isWayBillReceipt && (
              <FlexColumnStyled className="summary">
                <SpaceBetweenStyled>
                  <Paragraph color="black" bold className="section_title">Sub Total: </Paragraph>
                  <Paragraph color="black" bold
                             className="section_title">{currencyFormatter(orderSummary.subTotal)}</Paragraph>
                </SpaceBetweenStyled>
      
                {orderSummary.totalDiscount > 0 && (
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Discount: </Paragraph>
                    <Paragraph color="black" bold>- {currencyFormatter(orderSummary.totalDiscount || 0)}</Paragraph>
                  </SpaceBetweenStyled>
                )}
      
                {orderSummary.totalService > 0 && (
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Services: </Paragraph>
                    <Paragraph color="black" bold>+ {currencyFormatter(orderSummary.totalService)}</Paragraph>
                  </SpaceBetweenStyled>
                )}
      
                {orderSummary.totalTax !== 0 && (
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Tax: </Paragraph>
                    <Paragraph color="black" bold>+ {currencyFormatter(orderSummary.totalTax || 0)}</Paragraph>
                  </SpaceBetweenStyled>
                )}
      
                {!!data?.[0]?.group_order?.group_delivery && (
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Delivery: </Paragraph>
                    <Paragraph color="black"
                               bold>+ {currencyFormatter(data?.[0]?.group_order?.group_delivery || 0)}</Paragraph>
                  </SpaceBetweenStyled>
                )}
      
                {/*<Line />*/}
                <SpaceBetweenStyled className="total">
                  <Paragraph color="black" size={1.1} bold className="section_title">Total Cost: </Paragraph>
                  <Paragraph color="black" size={1.1}
                             bold>{currencyFormatter(data?.[0]?.group_order?.group_total_cost)}</Paragraph>
                </SpaceBetweenStyled>
      
                {data?.[0]?.bulk_details?.balance > 0 && (
                  <Fragment>
                    <SpaceBetweenStyled className="total">
                      <Paragraph color="black" size={1} bold className="section_title">Total Paid: </Paragraph>
                      <Paragraph color="black" bold>{currencyFormatter(getTotalAmountPaid() || 0)}</Paragraph>
                    </SpaceBetweenStyled>
          
                    <SpaceBetweenStyled className="total">
                      <Paragraph color="black" size={1} bold className="section_title">Balance: </Paragraph>
                      <Paragraph color="black" bold>{currencyFormatter(data?.[0]?.bulk_details?.balance)}</Paragraph>
                    </SpaceBetweenStyled>
                  </Fragment>
                )}
      
                {!!data?.[0]?.ex_currency && (
                  <Fragment>
                    <br/>
          
                    <SpaceBetweenStyled>
                      <Paragraph color="black" bold className="section_title">Exchange Rate: </Paragraph>
                      <Paragraph color="black"
                                 bold>{currencyFormatterFn("en-NG", data?.[0]?.ex_currency)(data?.[0]?.exchange_rate || 0)}</Paragraph>
                    </SpaceBetweenStyled>
          
                    <SpaceBetweenStyled>
                      <Paragraph color="black" bold className="section_title">Exchange Amount: </Paragraph>
                      <Paragraph color="black"
                                 bold>{currencyFormatterFn("en-NG", data?.[0]?.ex_currency)(data?.[0]?.group_order?.exchange_value || orderSummary.totalExchangeAmountValue || 0)}</Paragraph>
                    </SpaceBetweenStyled>
                  </Fragment>
                )}
      
                <Line/>
      
                <FlexStyled>
                  <Paragraph color="black" bold className="section_title">Payment Method:</Paragraph>
                  <Paragraph color="black" bold>
                    {lodash.capitalize(data[0]?.pay_mode)}
                  </Paragraph>
                </FlexStyled>
      
                {(!!data?.[0]?.banks && !!bank && bank?.bankInfo) && (
                  <FlexStyled>
                    <Paragraph color="black" bold className="section_title">Bank:</Paragraph>
                    <Paragraph color="black" bold>{bank.bankInfo}</Paragraph>
                  </FlexStyled>
                )}
      
                {(getPaymentLogs().length > 0 && ["split", "partial", "credit"].includes(data[0].pay_mode)) && (
                  <Fragment>
                    {getPaymentLogs().map(log => (
                      <FlexStyled>
                        <Paragraph bold color="black"
                                   className="section_title">{lodash.capitalize(log.method)}:</Paragraph>
                        <Paragraph bold color="black">{currencyFormatter(log.amount)}</Paragraph>
                      </FlexStyled>
                    ))}
                  </Fragment>
                )}
              </FlexColumnStyled>
            )}
  
            {!!data?.[0]?.group_order?.note && (
              <FlexColumnStyled>
                <FlexStyled $xGap={.3} $gap={.5} style={{marginTop: "1rem", marginBottom: ".3rem"}}>
                  <Paragraph color="black" size={1.1} bold className="section_title">Note:</Paragraph>
                  <Paragraph color="black" size={1.1} bold
                             className="section_title">{data?.[0]?.group_order?.note}</Paragraph>
                </FlexStyled>
              </FlexColumnStyled>
            )}
  
            <AllCenterStyled className="thank_you_message">
              <Paragraph size={1.1} color="black" bold>
                {business_details?.receipt_message || "Powered by Ovaloop, your everyday business solution..."}
              </Paragraph>
            </AllCenterStyled>
  
            <FlexColumnStyled $gap={.5}>
              <span></span>
              <AllCenterStyled className="barcode_div">
                <Barcode height={50} format="CODE128" value={data?.[0]?.group_id}/>
              </AllCenterStyled>
            </FlexColumnStyled>
  
            <SpaceBetweenStyled className="receipt_action">
              {!isFirstPrint && (
                <>
                  <Button className="print_button" text="Print" onClick={handlePrintReceipt}/>
                  {localStorage.getItem("isWayBill") === "true" && (
                    <Button
                      bgColor="black"
                      className="print_button"
                      text={isWayBillReceipt ? "Change to receipt" : "Change to waybill"}
                      onClick={setWayBillReceipt}
                    />
                  )}
                </>
              )}
            </SpaceBetweenStyled>
  
            {isMobile && <Button text="Close" onClick={onClose} className="close_action"/>}
          </SectionStyled>
        </ReceiptModalStyled>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default ReceiptModal


// {/*<FlexColumnStyled $gap={.2} className="items_purchased">*/}
//
// {/*  {data.map(product => (*/}
// {/*    <FlexColumnStyled $gap={.1} key={product?.id}>*/}
// {/*      <SpaceBetweenStyled className="product_item">*/}
// {/*        <Paragraph color="black"*/}
// {/*                   bold>{product?.product?.name || product?.product_name || product?.name}</Paragraph>*/}
// {/*        <Paragraph color="black" bold>*/}
// {/*          {!(product?.returned === true && product?.return_type === "order") && (*/}
// {/*            <Fragment>*/}
// {/*              {!!product?.meta_measurement?.name && `${currencyFormatter(product?.selling_price)} (x${product?.meta_measurement?.quantity} ${product?.meta_measurement?.name})`}*/}
// {/*              {!product?.meta_measurement?.name && `${currencyFormatter(product?.selling_price)} (x${product?.qty})`}*/}
// {/*            </Fragment>*/}
// {/*          )}*/}
// {/*  */}
// {/*          {product?.complimentary && <Paragraph noTop color="error"> Complimentary</Paragraph>}*/}
// {/*          {(product?.returned === true && product?.return_type === "order") &&*/}
// {/*            <Paragraph noTop color="error"> Returned</Paragraph>}*/}
// {/*        </Paragraph>*/}
// {/*      </SpaceBetweenStyled>*/}
//
// {/*      {(!!product?.service && product?.service.length > 0 && orderSummary.totalService > 0) && (*/}
// {/*        <div className="addon">*/}
// {/*          <Heading color="black" size={.8}>Add-On(s):</Heading>*/}
// {/*          {product?.service.map(addon => (*/}
// {/*            Number(addon.cost) > 0 && (*/}
// {/*              <SpaceBetweenStyled key={addon.id}>*/}
// {/*                <Paragraph color="black" bold>{addon.name}</Paragraph>*/}
// {/*                <Paragraph color="black" bold>{currencyFormatter(addon.cost || 0)}</Paragraph>*/}
// {/*              </SpaceBetweenStyled>*/}
// {/*            )*/}
// {/*          ))}*/}
// {/*        </div>*/}
// {/*      )}*/}
//
// {/*    </FlexColumnStyled>*/}
// {/*  ))}*/}
// {/*</FlexColumnStyled>*/}