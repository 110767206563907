import types from "../utils/types";

const {STRING, CURRENCY, NUMBER, DATE, ACTIVE_STATUS, DATE_ONLY, NAIRA_CURRENCY, DATE_WITH_DAY_NAME} = types

const v = (key, keyType = STRING) => ({ key, keyType })

const tableHeadersAndValueKeys = {
  categoriesTable: {
    headers: ["Name", "Description", "Actions"],
    values: [
      v("name"),
      v("description")
    ]
  },
  storesTable: {
    headers: ["Store", "Address", "Country", "State", "Actions"],
    values: [
      v("store_name"),
      v("address"),
      v("country_name"),
      v("state_name"),
    ]
  },
  debtorsTable: {
    headers: ["Customer Name", "Amount", "Customer Phone", "Actions"],
    values: [
      v("customer"),
      v("amount", CURRENCY),
      v("customer_phone"),
    ]
  },
  debtorsReportTable: {
    headers: ["Customer Name", "Amount", "Balance", "Date"],
    values: [
      v("customer"),
      v("total_amount", CURRENCY),
      v("balance", CURRENCY),
      v("date", DATE),
    ]
  },
  subCategoriesTable: {
    headers: ["Name", "Description", "Actions"],
    values: [
      v("name"),
      v("description")
    ]
  },
  nestedSubCategoriesTable: {
    headers: ["Name", "Description", "Actions"],
    values: [
      v("name"),
      v("description")
    ]
  },
  taxTable: {
    headers: ["Title", "Type", "Value", "Status", "Date Created", "Actions"],
    values: [
      v("title"),
      v("tax_type_name"),
      v("format_value"),
      v("active", ACTIVE_STATUS),
      v("date", DATE),
    ]
  },
  deliveryRateTable: {
    headers: ["Name", "Amount", "Actions"],
    values: [
      v("name"),
      v("amount", CURRENCY),
    ]
  },
  addOnTable: {
    headers: ["Name", "Cost", "Actions"],
    values: [
      v("name"),
      v("cost", CURRENCY)
    ]
  },
  stagesTable: {
    headers: ["Name", "Commission", "Actions"],
    values: [
      v("name"),
      v("commissionValue")
    ]
  },
  discountTable: {
    headers: ["Title", "Type", "Value", "Status", "Date Created", "Expiry Date", "Actions"],
    values: [
      v("title"),
      v("discount_type_name"),
      v("format_value"),
      v("active", ACTIVE_STATUS),
      v("date", DATE_ONLY),
      v("expiry", DATE_ONLY),
    ]
  },
  expenseCategoryTable: {
    headers: ["Name", "Actions"],
    values: [
      v("name")
    ]
  },
  businessSubscriptionTable: {
    headers: ["Name", "Amount", "Duration", "Limit", "Date Created", "Actions"],
    values: [
      v("name"),
      v("value", CURRENCY),
      v("duration", NUMBER),
      v("limit", NUMBER),
      v("created_at", DATE),
    ]
  },
  bankTable: {
    headers: ["Bank Name", "Account Name", "Account Number", "Actions"],
    values: [
      v("name"),
      v("account_name"),
      v("account_no"),
    ]
  },
  roleTable: {
    headers: ["Title", "Number Of Permissions", "Actions"],
    values: [
      v("name"),
      v("numberOfPermissions", NUMBER)
    ]
  },
  transactionWalletTable: {
    headers: ["Account Name", "Account Number", "Amount"],
    values: [
      v("account_name"),
      v("account_number"),
      v("amount", CURRENCY)
    ]
  },
  expenseTable: {
    headers: ["Category", "Amount", "Created by", "Status", "Date Created", "Actions"],
    values: [
      v("custom_category_name"),
      v("amount", CURRENCY),
      v("created_by_name"),
      v("status"),
      v("created_date", DATE),
    ]
  },
  reportExpenseTable: {
    headers: ["Category", "Amount", "Remark", "Status", "Date Created"],
    values: [
      v("category"),
      v("amount", CURRENCY),
      v("remark"),
      v("status"),
      v("created_date", DATE),
    ]
  },
  inventoryTable: {
    headers: ["Product", "Quantity", "Remark", "Status", "Performed By", "Date", "Action"],
    values: [
      v("product_name"),
      v("quantity"),
      v("remark"),
      v("status"),
      v("performed_by_name"),
      v("date", DATE),
    ]
  },
  requestRawMaterialsLogsTable: {
    headers: ["Group Request ID", "No. of Products", "Requested By", "Status", "Date", "Action"],
    values: [
      v("group_request_id"),
      v("number_of_product_requested"),
      v("performed_by_name"),
      v("group_request_status"),
      v("date_created", DATE),
    ]
  },
  expiryProductTable: {
    headers: ["Product", "Quantity", "Expiry Date", "Date Created", "Expiry Notification"],
    values: [
      v("product_name"),
      v("quantity"),
      v("expiry_date", DATE),
      v("date", DATE),
    ]
  },
  reportDownloadableTable: {
    headers: ["Report Type", "Start Date", "End Date", "Date Generated", "Download"],
    values: [
      v("report_type"),
      v("from_date", DATE_WITH_DAY_NAME),
      v("to_date", DATE_WITH_DAY_NAME),
      v("created_at", DATE),
    ]
  },
  supplierTable: {
    headers: ["Name", "Email", "Phone", "Address", "Country", "Action"],
    values: [
      v("name"),
      v("email"),
      v("phone"),
      v("address"),
      v("country_name"),
    ]
  },
  customerTable: {
    headers: ["First Name", "Last Name", "Email", "Phone", "Address", "Action"],
    values: [
      v("first_name"),
      v("last_name"),
      v("email"),
      v("phone"),
      v("main_address")
    ]
  },
  staffTable: {
    headers: ["First Name", "Last Name", "Email", "Phone", "Role", "Actions"],
    values: [
      v("first_name"),
      v("last_name"),
      v("email"),
      v("phone"),
      v("designation")
    ]
  },
  
  staffListForBackendTable: {
    headers: ["Full Name", "Business Name", "Email", "Phone", "Role", "Actions"],
    values: [
      v("full_name"),
      v("business_details_name"),
      v("email"),
      v("phone"),
      v("designation")
    ]
  },
  
  referralsListForBackendTable: {
    headers: ["First Name", "Last Name", "Email", "Status", "Sign up Count", "Subscribe Count"],
    values: [
      v("first_name"),
      v("last_name"),
      v("email"),
      v("approval_status"),
      v("sign_up_count", NUMBER),
      v("subscribe_count", NUMBER)
    ]
  },
  
  demoRequestListForBackendTable: {
    headers: ["Full Name", "Business Name", "Email", "Phone", "Industry", "Action"],
    values: [
      v("name"),
      v("business_name"),
      v("email"),
      v("contact"),
      v("industry")
    ]
  },
  
  productTable: {
    headers: ["Image", "Name", "Cost Price", "Selling Price", "Available Stock", "Category", "Sub-Category", "Action"],
    values: [
      v("image_path"),
      v("name"),
      v("cost_price", CURRENCY),
      v("selling_price", CURRENCY),
      v("stock_unit", NUMBER),
      v("category_name"),
      v("sub_category_name")
    ]
  },
  
  rawMaterialsTable: {
    headers: ["Image", "Name", "Cost Price", "Available Stock", "Category", "Action"],
    values: [
      v("image_path"),
      v("name"),
      v("cost_price", CURRENCY),
      v("stock_unit", NUMBER),
      v("category_name"),
    ]
  },
  
  faultyProductTable: {
    headers: ["Image", "Name", "Category", "Total Damaged", "Actions"],
    values: [
      v("image_path"),
      v("name"),
      v("category_name"),
      v("stock_unit", NUMBER),
    ]
  },
  
  productBulkDeleteTable: {
    headers: ["", "Image", "Name", "Cost Price", "Selling Price", "Available Stock", "Category", "Sub-Category"],
    values: [
      v("image_path"),
      v("name"),
      v("cost_price", CURRENCY),
      v("selling_price", CURRENCY),
      v("stock_unit", NUMBER),
      v("category_name"),
      v("sub_category_name")
    ]
  },
  
  deliveryItemsTable: {
    headers: ["", "ID", "Quantity", "Total Cost", "Customer", "Address", "Action"],
    values: [
      v("order_id"),
      v("qty", NUMBER),
      v("total_cost", CURRENCY),
      v("customer_name"),
      v("customer_address")
    ]
  },
  deliveryPackageItemsTable: {
    headers: ["", "ID", "No. Of Items", "Customer Name", "Customer Phone", "Date Created", "Action"],
    values: [
      v("package_item_id"),
      v("item_count", NUMBER),
      v("customer_name"),
      v("customer_phone"),
      v("date_created", DATE)
    ]
  },
  deliverySentItemsTable: {
    headers: ["ID", "Delivery Company", "Vehicle Type", "Items", "Status", "Cost", "Date Created", "Action"],
    values: []
  },
  orderTable: {
    headers: ["Order Id", "No. Of Items", "Customer", "Amount", "Date", "Payment Status", "Actions"],
    values: [
      v("group_id"),
      v("total_items", NUMBER),
      v("customer_name"),
      v("cost_after_tax", CURRENCY),
      v("date_created", DATE),
      v("status", STRING)
    ]
  },
  
  purchaseOrderTable: {
    headers: ["ID", "Purchase ID", "Date", "Supplier", "Cost", "Due Date", "Status", "Paid Status", "Actions"],
    values: [
      v("purchase_order_id"),
      v("purchase_id"),
      v("created_at", DATE),
      v("supplier_name"),
      v("total_amount", CURRENCY),
      v("due_date", DATE),
      v("status", STRING),
      v("payment_status"),
    ]
  },
  
  purchaseOrderOnHoldTable: {
    headers: ["Date Created", "Supplier", "No. of Products", "Total Cost", "Actions"],
    values: [
      v("hold_created_at", DATE),
      v("supplier_name"),
      v("hold_total_products", NUMBER),
      v("hold_total_cost", CURRENCY),
    ]
  },
  
  customerOrderDebtsTable: {
    headers: ["Customer Name", "Order Id", "Cost", "Amount Paid", "Balance", "Date", "Actions"],
    values: [
      v("customer_name"),
      v("group_id"),
      v("cost_after_tax", CURRENCY),
      v("total_amount_paid", CURRENCY),
      v("balance", CURRENCY),
      v("date_created", DATE),
    ]
  },
  
  orderWithDueDateTable: {
    headers: ["Order Id", "No. Of Items", "Customer", "Cost B/T", "Due Date", "Date Ordered", "Payment Status", "Actions"],
    values: [
      v("group_id"),
      v("total_items", NUMBER),
      v("customer_name"),
      v("cost_before_tax", CURRENCY),
      v("order_due_date", DATE_ONLY),
      v("date_created", DATE_ONLY),
      v("status", STRING)
    ]
  },
  reportOrderTable: {
    headers: ["Product Name", "Quantity", "Cost B/T", "Service Cost", "Tax", "Discount", "Cost A/T", "Profit"],
    values: [
      v("product"),
      v("quantity", NUMBER),
      v("cost_before_tax", CURRENCY),
      v("service_cost", CURRENCY),
      v("tax", CURRENCY),
      v("discount", CURRENCY),
      v("cost_after_tax", CURRENCY),
      v("total_profit", CURRENCY)
    ]
  },
  
  reportGroupSalesTable: {
    headers: ["Group Order ID", "Total Items", "Amount", "Customer", "Balance", "Tax", "Discount", "Date Created"],
    values: [
      v("group_id"),
      v("total_items", NUMBER),
      v("amount", CURRENCY),
      v("customer"),
      v("balance", CURRENCY),
      v("tax", CURRENCY),
      v("discount", CURRENCY),
      v("date_created", DATE_ONLY),
    ]
  },
  customerOrderTable: {
    headers: ["Order Id", "No. Of Items", "Cost B/T", "Cost A/T", "Payment Status", "Actions"],
    values: [
      v("group_id"),
      v("total_items", NUMBER),
      v("cost_before_tax", CURRENCY),
      v("cost_after_tax", CURRENCY),
      v("status", STRING)
    ]
  },
  customerSubscriptionTable: {
    headers: ["Name", "Usage Count", "Status", "Created Date", "Expiry Date"],
    values: [
      v("subscription_service_name"),
      v("usage_count", NUMBER),
      v("status"),
      v("created_at", DATE_ONLY),
      v("expiry_date", DATE_ONLY)
    ]
  },
  disbursedOrderTable: {
    headers: ["Order Id", "Sender's Name", "Receiver's Name", "Disburse Time", "Remark"],
    values: [
      v("order_id"),
      v("sender_name"),
      v("receiver_name"),
      v("disburse_time", DATE),
      v("remark"),
    ]
  },
  
  purchaseOrderProductsTable: {
    headers: ["Name", "Quantity", "Supplied Quantity", "Cost Price", "Total Cost"],
    values: [
      v("product_name"),
      v("requested_quantity"),
      v("supplied_quantity"),
      v("cost_price", CURRENCY),
      v("total_cost", CURRENCY),
    ]
  },
  
  orderProfitLogTable: {
    headers: ["Batch Number", "Quantity", "Unit Cost", "Cost Price", "Selling Price", "Profit", "Date Added"],
    values: [
      v("batch_number"),
      v("quantity"),
      v("unit_cost", CURRENCY),
      v("cost_price", CURRENCY),
      v("selling_price", CURRENCY),
      v("profit", CURRENCY),
      v("date", DATE)
    ]
  },
  
  splitPaymentRecordTable: {
    headers: ["Method", "Account Name", "Amount", "Date"],
    values: [
      v("method"),
      v("bank_and_account"),
      v("amount", CURRENCY),
      v("date", DATE)
    ]
  },
  
  productCostPricesTable: {
    headers: ["Batch Number", "Quantity", "Cost Price", "Active", "Expiry Date", "Date Added"],
    values: [
      v("batch_number"),
      v("qty_remaining"),
      v("cost_price", CURRENCY),
      v("current", ACTIVE_STATUS),
      v("expiry_date", DATE),
      v("date", DATE_ONLY)
    ]
  },
  
  productUnitMeasurementsTable: {
    headers: ["Name", "Quantity in Measurement", "Available Measurement", "Selling Price"],
    values: [
      v("name"),
      v("base_quantity_name"),
      v("available_quantity_name"),
      v("selling_price", CURRENCY)
    ]
  },
  
  salesAttachmentTable: {
    headers: ["Name", "Quantity"],
    values: [
      v("product_name"),
      v("qty", NUMBER),
    ]
  },
  
  productionAttachmentTable: {
    headers: ["Name", "Quantity", "Unit Measurement"],
    values: [
      v("product_name"),
      v("qty", NUMBER),
      v("unit_measurement")
    ]
  },
  
  productionStagesTable: {
    headers: ["Name"],
    values: [
      v("name"),
    ]
  },
  
  productExpiryTable: {
    headers: ["Quantity", "Expiry Date", "Date Created", "Status", "Actions"],
    values: [
      v("quantity", NUMBER),
      v("expiry_date", DATE),
      v("date_created", DATE),
      v("is_active", ACTIVE_STATUS)
    ]
  },
  
  purchaseOrderPaymentRecordTable: {
    headers: ["Amount", "Date", ""],
    values: [
      v("amount", CURRENCY),
      v("date", DATE),
      v("ok")
    ]
  },
  
  returnedOrdersLogTable: {
    headers: ["Product", "Quantity", "Performed By", "Date"],
    values: [
      v("product_name"),
      v("quantity"),
      v("staff"),
      v("date", DATE)
    ]
  },
  
  refundLogTable: {
    headers: ["Method", "Amount", "Performed By", "Date", "Remark"],
    values: [
      v("method"),
      v("refund_value", CURRENCY),
      v("staff"),
      v("date", DATE),
      v("remark"),
    ]
  },
  
  tableItemsTable: {
    headers: ["Name", "Capacity", "Status", "Action"],
    values: [
      v("name"),
      v("capacity", NUMBER),
      v("status")
    ]
  },
  staffWipTable: {
    headers: ["Service Name", "Stage Name", "Status", "Date Created"],
    values: [
      v("service_name"),
      v("stage_name"),
      v("status"),
      v("date_created", DATE),
    ]
  },
  staffWipReportTable: {
    headers: ["Service Name", "Stage Name", "Staff Assigned", "Date Created"],
    values: [
      v("service"),
      v("stage"),
      v("staff"),
      v("date", DATE),
    ]
  },
  subscriptionLogTable: {
    headers: ["Package", "Amount Paid", "Sub Type", "Extra Store", "Extra User", "Status", "Date paid"],
    values: [
      v("packageNameAndDuration"),
      v("amount", NAIRA_CURRENCY),
      v("sub_type"),
      v("extra_store", NUMBER),
      v("extra_user", NUMBER),
      v("status"),
      v("date", DATE)
    ]
  },
  
  subscriptionAccountTable: {
    headers: ["Amount", "Status", "Subscription Type", "Date", "Actions"],
    values: [
      v("amount", NAIRA_CURRENCY),
      v("transaction_type"),
      v("sub_type"),
      v("date", DATE)
    ]
  },
  
  transferLogTable: {
    headers: ["Account Name", "Account Number", "Amount", "Payment Status", "Order ID", "Date Paid", "Actions"],
    values: [
      v("account_name"),
      v("account_number"),
      v("amount", NAIRA_CURRENCY),
      v("status"),
      v("group_id"),
      v("date", DATE)
    ]
  },
  
  settlementLogTable: {
    headers: ["ID", "Account Name", "Account Number", "Amount", "Payment Status", "Date Created", "Action"],
    values: [
      v("settlement_id"),
      v("account_name"),
      v("account_number"),
      v("amount", NAIRA_CURRENCY),
      v("status"),
      v("date", DATE)
    ]
  },
  
  ovaloopPayTransactionTable: {
    headers: ["Sender Acc. Name", "Sender Acc. Number", "Amount", "Status", "Date Created", "Action"],
    values: [
      v("sourceAccountName"),
      v("sourceAccountNumber"),
      v("transactionAmount", NAIRA_CURRENCY),
      v("ovaloopStatus"),
      v("createdAt", DATE)
    ]
  },
  
  customPaymentMethodTable: {
    headers: ["Name", "Actions"],
    values: [ v("payment_method") ]
  },

  businessPurchasedSubscriptionsTable: {
    headers: ["Subscription Name", "Customer Name", "Payment Status", "Created Date", "Actions"],
    values: [
      v("subscription_service_name"),
      v("customer_name"),
      v("payment_status"),
      v("created_at", DATE_ONLY)
    ]
  },
  
  businessPurchasedSubscriptionsReportTable: {
    headers: ["Subscription Name", "Customer Name", "Usage Count", "Created Date", "Expiry Date",],
    values: [
      v("subscription_service"),
      v("customer"),
      v("usage_count"),
      v("date", DATE_ONLY),
      v("expiry_date", DATE_ONLY),
    ]
  },
  
  customerWalletLogTable: {
    headers: ["Amount", "Transaction Type", "Previous Balance", "Updated Balance", "Date", "Action"],
    values: [
      v("amount", CURRENCY),
      v("transaction_type"),
      v("previous_balance", CURRENCY),
      v("updated_balance", CURRENCY),
      v("date", DATE_ONLY)
    ]
  },
  
  tipsTable: {
    headers: ["Amount", "Staff Name", "Created By", "Status", "Date", "Action"],
    values: [
      v("amount", CURRENCY),
      v("staff_name"),
      v("created_by_name"),
      v("status"),
      v("date", DATE),
    ]
  },
  
  staffTipsTable: {
    headers: ["Amount", "Created By", "Status", "Date"],
    values: [
      v("amount", CURRENCY),
      v("created_by_name"),
      v("status"),
      v("date", DATE),
    ]
  },
  
  activityLogsTable: {
    headers: ["User", "Activity Type", "remark", "Date"],
    values: [
      v("user_full_name"),
      v("activity"),
      v("remark"),
      v("date", DATE),
    ]
  },
  
  productionUnitMeasurementTable: {
    headers: ["Name", "Actions"],
    values: [ v("name") ]
  },
  
  productionTable: {
    headers: ["Product", "Quantity", "Batch Number", "Current Stage", "Status", "Date", "Action"],
    values: [
      v("product_name"),
      v("projected_quantity", NUMBER),
      v("batch_number"),
      v("stage_name"),
      v("status"),
      v("date_created", DATE)
    ]
  },
  
  productionStageTable: {
    headers: ["Stage", "Expected Qty", "Completed Qty", "Incomplete Qty", "Status",
      "Date Created",
      // "Date Completed",
      "Action"],
    values: [
      v("current_stage_name"),
      v("expected_quantity", NUMBER),
      v("completed_quantity", NUMBER),
      v("incomplete_quantity", NUMBER),
      v("status"),
      v("date_created", DATE_ONLY),
      // v("completed_time", DATE_ONLY)
    ]
  },
  
  
  // STAFF DASHBOARD DATA
  
  accountsTable: {
    headers: ["Business Name", "Owner Name", "Owner phone", "Email", "Package", "Date created", "Action"],
    values: [
      v("business_name"),
      v("owner_name"),
      v("owner_phone"),
      v("owner_email"),
      v("package_name"),
      // v("active_stores", NUMBER),
      v("date", DATE)
    ]
  },
  
  kycTable: {
    headers: ["Business Name", "Account Name", "Bank Name", "Account Number", "Status", "Action"],
    values: [
      v("business_name"),
      v("account_name"),
      v("bank_name"),
      v("account_number"),
      v("status")
    ]
  },
  
  userListForStaffBackend: {
    headers: ["Full Name", "Email", "Phone", "Business Name", "Action"],
    values: [
      v("full_name"),
      v("email"),
      v("phone_number"),
      v("business"),
    ]
  },
}


export default tableHeadersAndValueKeys